
.placeholder{
    display: inline-block;
    position: relative;
    width:155px;
    height:155px;
    margin:2px;
}

.clip{
    .no-select;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 155px;
    height: 155px;
    margin: 12px 2px 2px 2px;
    transition: all 300ms @easeInOutCirc;
    transform: translateZ(0);
    cursor: pointer;

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    video{
        object-fit: fill;
        .layer;
        display: none;

        &.show{
            display: block;
        }
    }

    img{
        object-fit: fill;
        .layer;
    }
    
    .overlay{
        .layer;
        display: table;
        text-align:center;
        box-sizing:border-box;
        padding:5px;
        
        &:hover{
            background:rgba(255,255,255,0.2);
        }

        .label {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            color:white;
            .futuraPTBold;
            font-size: 22px;
            box-sizing: border-box;
            line-height:24px;
            height:15px;
            text-transform: uppercase;
            
            &.large{
                height: 38px;
                padding-top: 4px;
            }

            select{
                display: inline-block;
                background:@darkGreen;
                -webkit-appearance: none;
                border-radius: 0px;
                font-size:10px;
                padding:2px;
                font-family: 'TrefoilSans-SemiBold';
                border:none;
                color:white;
                text-align:center;
                
                &::-webkit-input-placeholder {
                  color: white;
                  font-family: 'TrefoilSans-SemiBold';
                }
                &::-moz-placeholder {
                  color: white;
                  font-family: 'TrefoilSans-SemiBold';
                }
                &:-ms-input-placeholder {
                  color: white;
                  font-family: 'TrefoilSans-SemiBold';
                }
                &:-moz-placeholder {
                  color: white;
                  font-family: 'TrefoilSans-SemiBold';
                }

                option{
                    font-family: 'TrefoilSans-SemiBold';
                }
            }
        }

        .experience-wrapper {
            .flex-center-justify;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 24px;
            background-color: @darkGreen;

            input {
                .circularStdBold;
                max-width: 70px;
                height: 16px;
                margin-right: 10px;
                padding: 0 10px;
                border: none;
                border-radius: 0px;
                box-sizing: border-box;
                -webkit-appearance: none;
                background: white;
                color: @gsGreen;
                font-size: 11px;
                line-height: 16px;
            }

            .years {
                .futuraPTBold;
                color:white;
                font-size: 11px;
                letter-spacing: 0.16em;
                text-transform: uppercase;
            }
        }
    }

    @media @laptop {

        width: 225px;
        height: 225px;
        margin: 17px 19px 0 0;

        &:last-child {
            margin: 0;
        }

        .overlay {
            .label {
                font-size: 32px;
                line-height: 30px;
                height:20px;
                top:-20px;
                
                &.large{
                    height:48px;
                }

                select{
                    font-size:12px;
                    padding:3px;
                }
            }

            .experience-wrapper {
                height: 30px;

                input {
                    height: 18px;
                    font-size: 14px;
                    line-height: 18px;
                }

                .years {
                    font-size: 14px;
                }
            }
        }
    }

    @media @desktop {
        width: 338px;
        height: 338px;
        margin: 22px 28px 0 0;

        .overlay {
            padding:10px;
            .label {
                font-size: 46px;
                line-height: 43px;
                height:25px;
                top:-25px;

                &.large{
                    height:62px;
                }
            }

            .experience-wrapper {
                height: 40px;

                input {
                    height: 26px;
                    font-size: 22px;
                    line-height: 26px;
                }

                .years {
                    font-size: 22px;
                }
            }
        }
    }
}