
.selection-indicator{
    position: absolute;
    width:30px;
    height:30px;
    top:0px;
    left:0px;
    .dinCondensedBold;
    color:white;
    text-align:center;
    font-size: 26px;
    line-height: 36px;
    color: white;

    &.selected{
        background: black;
        border-right:2px solid white;
        border-bottom:2px solid white;
    }

    @media @laptop{
        width:40px;
        height:40px;
        font-size: 36px;
        line-height: 50px;
    }

    @media @desktop{
        width:60px;
        height:60px;
        font-size: 51px;
        line-height: 75px;
    }
}