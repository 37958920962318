
.preview{
    background:#ddd;
    width:100%;
    height:10px;
    position: relative;
    margin-top:3px;
    margin-bottom:15px;
    
    .overlay{
        position:absolute;
        top:0px;
        left:0px;
        width:720px;
        height:720px;
        transform-origin: top left;
        display: table;
        padding:35px;
        box-sizing: border-box;
    }

    .text-wrapper{
        display: table-cell;
        text-align:center;
        vertical-align: middle;
    }

    .text{
        display: inline-block;
        color:white;
        font-size:150px;
        line-height: 130px;
        text-transform: uppercase;
        -webkit-font-smoothing:antialiased;
        font-family: 'HelveticaNeue-BoldCondObl';
        color:white;
    }
}