@tracksBottomBreak: 45px;
@tracksTopBreak: 12.5px;

.select-music {
    p {
        max-width: 320px;
        margin: auto;
    }
    
    .flex-wrapper {
        .description {
            margin-bottom: 45px;
        }

        p {
            font-size: 14px;
            letter-spacing: 0.05em;
        }

        .btn-create {
            border: 2px solid @gsPurple;

            &.create-rollover {
                transition:all 100ms @easeOutQuad;

                &:hover{
                    background:white;
                    color:@gsPurple;
                    box-sizing: border-box;
                }
            }

            .icon-next {
                margin-left: 8px;
            }
        }
    }

    .tracks {
        display: flex;
        justify-content: center;
        margin-top: @tracksTopBreak;
        margin-bottom: @tracksBottomBreak;
    }

    @media @laptop {
        .flex-wrapper {
            .flex-center-justify;
            flex-direction: column;

            p {
                .circularStdBook;
                font-size: 14px;
                max-width: 350px;
                line-height: 20px;
                letter-spacing: 0.025em;
                color:@textGrey;
                margin: 0px 0 20px 0;
            }

            .btn-create {
                .futuraPTHeavy;
                padding: 0 23px;
                font-size: 14px;
                height: 30px;
                line-height: 27px;
                letter-spacing: 0.2em;
            }

            padding-bottom:200px;
        }
    }

    @media @desktop {
        .flex-wrapper {
            p {
                max-width: 522px;
                margin: 10px 0 28px 0;
                font-size: 22px;
                line-height: 35px;
            }

            .btn-create {
                padding: 0 36px;
                font-size: 17px;
                height: 40px;
                line-height: 37px;
            }
        }

    }
}