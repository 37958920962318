@import "reset.less";
@import "mixins.less";
@import "transitions.less";
@import "colors.less";
@import "fonts.less";
@import "buttons.less";
@import "components/**/*.less";

html {
    font-variant-ligatures: none;
    -webkit-font-smoothing:antialiased;
}

.module{
    position: fixed;
    transform:translateX(100%);
    width:100%;
    box-sizing: border-box;
    padding-bottom:70px;
    
    &.active{
        transform:translateX(0%);
        position: static;
    }

    &.desktop-mode {
        position: static;
        transform:translateX(0%);
    }
    
    &.blue{
        background: @bgBlue;
        padding-left:20px;
        padding-right:20px;
    }

    &.white{
        background:#fff;
        padding-left:20px;
        padding-right:20px;
    }
    
    .description{
        .circularStdBook;
        font-size:11px;
        margin-top: -5px;
        margin-bottom: 16px;
        text-align:center;
        width:100%;

        .white&{
            color:@textGrey;
        }

        .blue&{
            color:white;
        }
    }

    .module-header {

        .rule {
            width:100%;
            height:26px;

            .ccnr;

            .white&{
                .retina('../img/module_header_rule_black@2x.png', 57px, 1px);
            }

            .blue&{
                .retina('../img/module_header_rule@2x.png', 57px, 1px);
            }
        }
        
        &.mobile {
            padding-top:20px;

            .number{
                width:35px;
                height:35px;
                border-radius:50%;
                .dinCondensedBold;
                font-size:26px;
                text-align:center;
                line-height:41px;
                margin:0 auto;

                .blue&{
                    color:white;
                    border:2px solid white;
                }

                .white&{
                    color:@darkGreen;
                    border:2px solid @darkGreen;
                }
            }

            .step{
                display: none;
            }

            h2{
                text-align:center;
                color:white;
                .dinCondensedBold;
                text-transform:uppercase;
                font-size:21px;
                font-size: 25px;
                line-height: 24px;
                max-width:310px;
                margin:10px auto 0px auto;

                .blue&{
                    color:white;
                }

                .white&{
                    color:@darkGreen;
                }
            }
        }

        &.desktop {
            .flex-center-justify;
            flex-direction: column;
            padding: 50px 0 20px 0;

            .number {
                .dinCondensedBold;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                font-size: 40px;
                text-align: center;
                line-height: 60px;
                margin: 0 auto 10px auto;

                .blue&{
                    color:@bgBlue;
                    background-color: white;
                }

                .white&{
                    color:white;
                    background-color:@darkGreen;
                }
            }

            h2 {
                .dinCondensedBold;
                max-width: 470px;
                font-size: 28px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 400;

                // keep this here
                @media @desktop {
                    max-width: 706px;
                    font-size: 42px;
                }

                .blue&{
                    color:white;
                }

                .white&{
                    color:@darkGreen;
                }
            }

            .rule {
                margin: 0;
                margin-top: 20px;
            }
        }

        @media @desktop {
            padding: 80px 0 30px 0;

            .number {
                width: 75px;
                height: 75px;
                font-size: 42px;
                line-height: 75px;
                margin: 0 auto 20px auto;
            }

            .rule {
                margin: 0;
                margin-top: 30px;

                .white&{
                    .retina('../img/module_header_rule_black@2x.png', 114px, 2px);
                }

                .blue&{
                    .retina('../img/module_header_rule@2x.png', 114px, 2px);
                }
            }
        }
    }
}
