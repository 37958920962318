
@headerHeight:50px;
@mobileSubHeaderHeight:0px;
@desktopSubHeaderHeight:0px;
@drawerLinkHeight:24px;
@drawerTopPadding:9px;
@drawerBottomPadding:4px;

.header {
    position: fixed;
    z-index:10000;
    top:0px;
    width:100%;
    text-align:center;
    height: @headerHeight + @mobileSubHeaderHeight;
    border-bottom:1px solid #ddd;

    // @media @laptop {
    //     height: 97px;
    // }

    // @media @desktop {
    //     height: 122px;
    // }
    
    .header-content{
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:@headerHeight;
        background: white;
    }
    
    .desktop-sub-header{

        display: none;
        position: absolute;
        top:@headerHeight;
        left:0px;
        width:100%;
        height:@desktopSubHeaderHeight;
        background:white;

        @media @1024{
            display: block;
        }

        @media @laptop {
            height: 60px;
        }

        @media @desktop {
            height: 85px;
        }

        .inner-content{
            width:100%;
            max-width:888px;
            margin:0 auto;
            height:100%;
            display: block;

        }

        .active-bar {
            position: absolute;
            height: 5px;
            background-color: @gsPurple;
            transition: left 250ms @easeInOutCirc,
                        right 250ms @easeInOutCirc;
        }

        .header-link{
            .no-select;
            display: inline-block;
            width:130px;
            height:100%;
            line-height:@desktopSubHeaderHeight;
            color:@textGreyDarker;
            text-transform:uppercase;
            text-align:center;
            text-decoration:none;
            box-sizing:border-box;
            vertical-align: top;
            margin-left:59px;
            transition:background-color 100ms @easeOutQuad;
            &.first{
                margin-left: 0px;
            }

            @media @laptop {
                .futuraPTBold;
                font-size: 11px;
                letter-spacing: 0.2em;
                line-height: 60px;
            }

            @media @desktop {
                .futuraPTBold;
                font-size: 11px;
                line-height: 85px;
                font-size: 16px;
            }
        }
    }

    .mobile-sub-header{
        position: absolute;
        top:@headerHeight;
        left:0px;
        width:100%;
        height:@mobileSubHeaderHeight;
        background:white;
        border-bottom:1px solid #ddd;
            
        @media @1024{
            display: none;
        }

        .active-link{
            .layer;
            .futuraPTBold;
            text-transform:uppercase;
            background:white;
            border-bottom:1px solid #949ca1;
            text-align:center;
            line-height:@mobileSubHeaderHeight;
            font-size:12px;
            letter-spacing:1px;
            color:@gsGreen;
        }

        .drawer{
            position: absolute;
            width:100%;
            height:(@drawerLinkHeight * 5) + @drawerTopPadding + @drawerBottomPadding;
            top:@mobileSubHeaderHeight;
            left:0px;
            padding-top:@drawerTopPadding;
            padding-bottom:@drawerBottomPadding;
            background:white;
            
            transform:translateY((-@drawerLinkHeight * 5) - @mobileSubHeaderHeight);
            transition:transform 700ms @easeOutQuint;

            &.open{
                transform:translateY(0px);
            }

            .drawer-link{
                display: block;
                width:100%;
                height:@drawerLinkHeight;
                padding:0px;
                margin:0px;
                .futuraPTBold;
                font-size:11px;
                line-height:@drawerLinkHeight;
                text-transform:uppercase;
                letter-spacing:2px;
                text-align:center;
                text-decoration:none;
                color:@textGrey;

                &.active{
                    color:@gsPurple;
                }
            }
        }
    }

    .logo-hustle{
        display: inline-block;  
        .retina('../img/imposium_header_logo_black@2x.png', 113px, 30px);
        .ccnr;
        width:113px;
        height:30px;
        vertical-align: top;
        margin-top:10px;
    }

    .logo-ces{
        .dinCondensedBold;
        color:white;
        display: inline-block;
        vertical-align: top;
    }
}