
@font-face {
  font-family: 'CircularStd-Bold';
  src: url('/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Bold.otf')  format('opentype'),
         url('/fonts/CircularStd-Bold.woff') format('woff'), url('/fonts/CircularStd-Bold.ttf')  format('truetype'), url('/fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd-Book';
  src: url('/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),  url('/fonts/CircularStd-Book.otf')  format('opentype'),
         url('/fonts/CircularStd-Book.woff') format('woff'), url('/fonts/CircularStd-Book.ttf')  format('truetype'), url('/fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TrefoilSans-SemiBold';
  src: url('/fonts/TrefoilSans-SemiBold.eot?#iefix') format('embedded-opentype'),  url('/fonts/TrefoilSans-SemiBold.otf')  format('opentype'),
         url('/fonts/TrefoilSans-SemiBold.woff') format('woff'), url('/fonts/TrefoilSans-SemiBold.ttf')  format('truetype'), url('/fonts/TrefoilSans-SemiBold.svg#TrefoilSans-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINCondensed-Bold';
  src: url('/fonts/DINCondensed-Bold.eot?#iefix') format('embedded-opentype'),  url('/fonts/DINCondensed-Bold.ttf')  format('truetype'),
         url('/fonts/DINCondensed-Bold.woff') format('woff'), url('/fonts/DINCondensed-Bold.svg#TrefoilSans-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeue-BoldCondObl';
  src: url('/fonts/HelveticaNeue-BoldCondObl.eot?#iefix') format('embedded-opentype'),  url('/fonts/HelveticaNeue-BoldCondObl.otf')  format('opentype'),
         url('/fonts/HelveticaNeue-BoldCondObl.woff') format('woff'), url('/fonts/HelveticaNeue-BoldCondObl.ttf')  format('truetype'), url('/fonts/HelveticaNeue-BoldCondObl.svg#TrefoilSans-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SuperClarendon-Italic';
  src: url('/fonts/SuperclarendonRgItalic.ttf?#iefix') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.circularStdBook{
    font-family:'CircularStd-Book';
    font-weight:normal;
    font-style:normal;
}

.circularStdBold{
    font-family:'CircularStd-Bold';
    font-weight:normal;
    font-style:normal;
}

.futuraPTMed{
    font-family: futura-pt, sans-serif;
    font-weight: 450;
    font-style: normal;    
}

.futuraPTMedObl{
    font-family: futura-pt, sans-serif;
    font-weight: 450;
    font-style: italic;    
}

.futuraPTHeavy{
    font-family: futura-pt, sans-serif;
    font-weight: 800;
    font-style: normal;    
}

.futuraPTBookObl{
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: italic;    
}

.futuraPTDemi{
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-style: normal;    
}

.futuraPTBold{
    font-family: HelveticaNeue-BoldCondObl, sans-serif;
    font-weight: 700;
    font-style: normal;    
}

.dinCondensedBold{
    font-family: DINCondensed-Bold, sans-serif;
    font-weight: 800;
    font-style: normal;
}

.superClarendonItalic {
    font-family: 'SuperClarendon-Italic';
    font-style: italic;
    font-weight: 400;
}
