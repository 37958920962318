
.select-clips {

    text-align: center;

    p {
        max-width: 320px;
        margin: auto;
    }

    .flex-wrapper {
        p {
            font-size: 14px;
        }
    }

    .clips{
        text-align: center;
    }
    
    .btn-clear-selection {
        .futuraPTHeavy;
        background: none;
        border: 1px solid @gsPurple;
        color:@gsPurple;
        height: 26px;
        line-height: 25px;
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 0 17.5px;
        font-size: 12px;
        letter-spacing: 0.2em;

        &:hover{
            background:@gsPurple;
            color:white;
        }
    }  

    @media @laptop {
        .flex-wrapper {
            .flex-center-justify;
            flex-direction: column;

            .clips {
                .clip-row {
                    &:last-child {
                        margin: 0;
                    }
                }
            }

            p {
                .circularStdBook;
                max-width: 630px;
                font-size: 14px;
                letter-spacing: 0.025em;
                margin: 0;

                &.tip {
                    color: white;
                    font-style: italic;
                    margin-bottom: 40px;
                }
            }
        }

        .btn-clear-selection {
            border: 3px solid @gsPurple;
            height: 26px;
            line-height: 20px;
            margin-top: 25px;
            font-size: 14px;
        } 
    }

    @media @desktop {
        .flex-wrapper {
            
            p {
                max-width: 1000px;
                font-size: 22px;

                &.tip {
                    margin-bottom: 60px;
                }
            }

            .clear-selection {
                border: 4px solid white;
                height: 40px;
                line-height: 33px;
                margin-top: 40px;
                padding: 0 31px;
                font-size: 22px;
            }
        }
    }
}