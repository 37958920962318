
@indicatorSize:24px;

.track-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
        margin-right: 19px;
    }

    .track {
        width:150px;
        height:150px;
        position: relative;
        display: inline-block;
        cursor: pointer;

        &:hover{
            .selection-indicator {
                background-color:rgba(0, 168, 80, 0.4);

                &.selected{
                    background: @darkGreen;
                }
            }
        }

        audio {
            visibility: hidden;
            width: 0;
            height: 0;
        }

        .play-icon {
            position: absolute;
            top: 75px;
            left: 75px;
            width: 52px;
            height: 52px;
            margin-top: -26px;
            margin-left: -26px;
            transition: background-color 50ms @easeOutQuad;

            // &:hover {
            //     background-color: white;
            // }

            &.play {
                .retina('../../img/play_icon@2x.png', 52px, 52px);
            }
            
            &.pause {
                .retina('../../img/pause_icon@2x.png', 52px, 52px);
            }
        }

        .track-bg {
            width: 100%;
            height: 100%;

            &.tools {
                background: url('../../img/hustle_cover.jpg');
                background-repeat: no-repeat;
                background-size: cover;
            }

            &.therapy {
                background: url('../../img/swagger_cover.jpg');
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }

    .track-label {
        .futuraPTBold;
        font-size: 13px;
        color: @darkGreen;
        text-align: center;
        text-transform: uppercase;
    }

    @media @laptop {
        &:first-child {
            margin-right: 19px;
        }

        .track {
            height: 200px;
            width: 200px;

            .play-icon {
                top: 100px;
                left: 100px;
                width: 72px;
                height: 72px;
                margin-top: -36px;
                margin-left: -36px;

                &.play {
                    .retina('../../img/play_icon@2x.png', 72px, 72px);
                }
                
                &.pause {
                    .retina('../../img/pause_icon@2x.png', 72px, 72px);
                }
            }
        }

        .track-label {
            .futuraPTBold;
            font-size: 16px;
            text-align: center;
            letter-spacing: 0.05em;
        }
    }

    @media @desktop {
        &:first-child {
            margin-right: 28px;
        }

        .track {
            height: 305px;
            width: 305px;

            .play-icon {
                top: 152.5px;
                left: 152.5px;
                width: 100px;
                height: 100px;
                margin-top: -50px;
                margin-left: -50px;

                &.play {
                    .retina('../../img/play_icon@2x.png', 100px, 100px);
                }
                
                &.pause {
                    .retina('../../img/pause_icon@2x.png', 100px, 100px);
                }
            }
        }

        .track-label {
            font-size: 21px;
        }
    }
}

