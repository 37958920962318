.modal-wrapper {
    position: fixed;
    top: 0;
    z-index: 10001;
    height: 100%;
    width: 100%;

    .bg {
        .layer;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 320px;
        height: 220px;
        margin-left: -160px;
        margin-top: -110px;
        background-color: white;
        .flex-center-justify;
        flex-direction: column;

        &.browser-warning {
            height: 240px;
            margin-top: -120px;

            a {
                .circularStdBold;
                border-bottom: 1px solid @gsGreen;
                color: @gsGreen;
                letter-spacing: 0.05em;
                text-decoration: none;
                font-size: 14px;
                margin-top: 5px;
            }
        }

        &.cropper {
            height: 380px;
            margin-top: -190px;

            .crop-wrapper {
                margin-top: 16px;
                height: 200px;

                img {
                    max-height: 200px;
                }
            }

            .btn-crop {
                margin-top: 16px;
                border: 2px solid @gsPurple;
                transition:all 100ms @easeOutQuad;

                &:hover{
                    background:white;
                    color:@gsPurple;
                    box-sizing: border-box;
                }
            }
        }

        h2 {
            .futuraPTBold;
            font-size: 22px;
            color: @darkGreen;
            text-transform: uppercase;
            letter-spacing: 0.16em;
        }

        .rule {
            width: 57px;
            height: 1px;
            margin: 10px 0;
            .retina('../img/module_header_rule_black@2x.png', 57px, 1px);

            &.pulse {
                animation: pulseMobile 2200ms infinite;
            }
        }

        p {
            .circularStdBook;
            font-weight:400;
            max-width: 270px;
            color: @textGrey;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.04em;
        }

        .btn-back {
            background: none;
            color: @darkGreen;
            display: flex;
            align-items: center;
            margin-top: 16px;

            i {
                &.icon-back {
                    margin-right: 8px;
                    .retina('/img/icon_arrow_prev_black@2x.png', 10px, 10px, true);
                }
            }
        }

        .btn-proceed {
            margin-top: 20px;
        }
    }

    @media @laptop {
        .content {
            width: 592px;
            height: 322.5px;
            margin-left: -296px;
            margin-top: -161.25px;

            &.browser-warning {
                a {
                    margin-top: 11px;
                    font-size: 14px;
                }
            }

            &.cropper {
                width: 692px;
                height: 560px;
                margin-left: -346px;
                margin-top: -280px;

                .crop-wrapper {
                    margin-top: 16px;
                    height: 295px;

                    img {
                        max-height: 295px;
                    }
                }

                .btn-crop {
                    margin-top: 16px;
                }
            }

            h2 {
                .futuraPTBold;
                max-width: 470px;
                font-size: 28px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: .16em;
            }

            .rule {
                margin: 20px 0;
                height: 2px;
                .retina('../img/module_header_rule_black@2x.png', 57px, 2px);

                &.pulse {
                    animation: pulseLaptop 2200ms infinite;
                }
            }

            p {
                .circularStdBook;
                max-width: 471px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: .025em;
            }

            .btn-back {
                font-size: 16px;
                margin-top: 16px;

                i {
                    &.icon-back {
                        .retina('/img/icon_arrow_prev_black@2x.png', 12.5px, 12.5px, true);
                    }
                }
            }

            .btn-proceed {
                margin-top: 40px;
            }
        }
    }

    @media @desktop {
        .content {
            width: 888px;
            height: 483px;
            margin-left: -444px;
            margin-top: -241.5px;

            &.browser-warning {
                a {
                    margin-top: 15px;
                    font-size: 22px;
                }
            }

            &.cropper {
                width: 988px;
                height: 820px;
                margin-left: -494px;
                margin-top: -410px;

                .crop-wrapper {
                    margin-top: 16px;
                    height: 428px;

                    img {
                        max-height: 428px;
                    }
                }
            }

            h2 {
                max-width: 706px;
                font-size: 42px;
            }

            .rule {
                margin: 30px 0;

                &.pulse {
                    animation: pulseDesktop 2200ms infinite;
                }
            }

            p {
                max-width: 676px;
                font-size: 22px;
                line-height: 32px;
                letter-spacing:0;
            }

            .btn-back {
                font-size: 22px;
                margin-top: 50px;

                i {
                    &.icon-back {
                        .retina('/img/icon_arrow_prev_black@2x.png', 20px, 20px, true);
                    }
                }
            }

            .btn-proceed {
                margin-top: 60px;
            }
        }
    }
}

@keyframes pulseMobile {
    0%   { transform: scaleX(1); }
    99%  { transform: scaleX(4); }
}

@keyframes pulseLaptop {
    0%   { transform: scaleX(1); }
    99%  { transform: scaleX(8); }
}

@keyframes pulseDesktop {
    0%   { transform: scaleX(1); }
    99%  { transform: scaleX(12); }
}
