
.welcome {

    background:#fff;
    text-align:center;
    
    //BUILD ANIMATION
    &.show{

        .hero{
            .bg{
                opacity: 1;
                transform:scale(1);
            }
            .content-wrapper{
                
                .copy{
                    .create{
                        opacity:1;
                        transform:translateY(0px);
                    }
                    .commercial{
                        opacity:1;
                        transform:translateY(0px);
                    }
                    .rules{
                        opacity:1;
                    }
                }
            }
        }

        .info{
            opacity:1;
        }

        #btn-get-started-mobile{
            opacity: 1;
            transform:translateY(0px);
        }
    }

    .hero {
        width:100%;
        height:160px;
        position: relative;
        overflow:hidden;

        .bg{
            .layer;
            background-image: url('/img/welcome_hero_mobile.jpg');
            background-size: cover;

            //BUILD ANIMATION
            opacity: 0;
            transform:scale(1.05);
            transform-orgin:center center;
            transition:opacity 300ms @easeOutQuad, transform 1200ms @easeOutQuad;
        }

        .content{
            .layer;
            display: table;
        }

        .content-wrapper {
            display: table-cell;
            vertical-align: middle;

            .copy{
        
                color:white;

                .bd{
                    .futuraPTBold;
                    text-transform:uppercase;
                    font-size: 30px;
                }
                
                .rules{
                    width:100%;
                    display:block;
                    height:24px;
                    .retina('/img/hero_rules@2x.png', 250px, 2px);
                    .ccnr;

                    .it{
                        .superClarendonItalic;
                        display: inline-block;
                    }
                }

                //BUILD ANIMATION
                // .create{
                //     opacity: 0;
                //     transform:translateY(-10px);
                //     transition:all 900ms @easeOutQuint 400ms;
                // }

                // .commercial{
                //     opacity: 0;
                //     transform:translateY(10px);
                //     transition:all 700ms @easeOutQuad 400ms;
                // }   

                // .rules{
                //     opacity: 0;
                //     transition:all 900ms @easeOutQuint 400ms;
                // }

            }
        }

    }

    .info{

        //BUILD ANIMATION
        opacity: 0;
        transition:opacity 400ms @easeOutQuad 800ms;

        padding-left:20px;
        padding-right:20px;
        box-sizing: border-box;

        h1{
            .circularStdBold;
            color:@textGrey;
            margin: 45px auto 0px auto;
            width: 100%;
            max-width: 220px;
            font-size: 17px;
            line-height: 20px;
        }

        p{
            .circularStdBook;
            color:@textGrey;
            font-size: 14px;
            width: 100%;
            margin: 15px auto 0 auto;
            max-width: 350px;
            letter-spacing: 0px;
            line-height: 16px;
        }

        .get-started-wrapper {
            display: none;
        }
    }

    #preview{
        width:320px;
        height:320px;
        background-color: #dadada;
        margin: 25px auto 0px auto;
        border:1px solid #ddd;
    }

    #btn-get-started-mobile{
        margin-top: 25px;
        line-height: 17px;

        .icon-next {
            margin-left: 3px;
            margin-top: 3.66px;
            vertical-align: top;
        }

        //BUILD ANIMATION
        opacity: 0;
        transform:translateY(10px);
        transition:all 400ms @easeOutQuad 1000ms;
    }

    @media @laptop {
        .hero {
            
            height: 400px;

            .bg{
                background-image: url('/img/welcome_hero_laptop.jpg');
            }

            .content{
                .flex-center-justify;
            }

            .content-wrapper {
                width: 960px;
                display: flex;
                justify-content: flex-start;

                .copy {
                    .flex-center-justify;
                    flex-direction: column;
                    width: 470px;
                    letter-spacing: 0.16em;

                    .bd {
                        font-size: 50px;
                    }

                    .rules {
                        height: 32px;
                        .retina('/img/hero_rules@2x.png', 437.5px, 2px);

                        .it {
                            line-height: 32px;
                            font-size: 32px;
                            font-style: italic;
                        }
                    }
                }
            }
        }

        .info {
            .flex-center-justify;
            padding-top: 50px;

            .col {
                
                width:auto;

                &:first-child {
                    width: 470px;
                    margin-right: 20px;
                }
            }

            h1 {
                font-size: 23px;
                max-width: 350px;
                line-height: 26.5px;
                margin: 0 auto;
            }

            p {
                .circularStdBook;
                font-size: 14px;
                max-width: 370px;
                line-height: 20px;
                letter-spacing: 0.025em;
            }

            .get-started-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 20px auto 0 auto;
                cursor: pointer;

                span {
                    .futuraPTBold;
                    color: @gsPurple;
                    font-size: 11px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;

                }

                .arrow {
                    width: 12px;
                    height: 12px;
                    .retina('/img/purple-arrow@2x.png', 12px, 12px);
                    transition:transform 400ms @easeOutBack;
                }

                &:hover{
                    .arrow{
                        transform:translateY(7px);
                    }
                }
            }

            #preview {
                margin: 0;
                width: 470px;
                height: 470px;
            }
        }

        #btn-get-started-mobile {
            display: none;
        }
    }

    @media @desktop {
        .hero {
            height: 597px;
            
            .bg{
                background-image: url('../../img/welcome_hero_desktop.jpg');
            }

            .content-wrapper {
                width: 1430px;

                .copy {
                    width: 706px;

                    .bd {
                        font-size: 74px;
                    }

                    .rules {
                        height: 48px;
                        .retina('/img/hero_rules@2x.png', 656px, 2px);

                        .it {
                            line-height: 48px;
                            font-size: 48px;
                        }
                    }
                }
            }
        }

        .info {
            padding-top: 80px;

            .col {
                &:first-child {
                    width: 706px;
                    margin-right: 28px;
                }
            }

            h1 {
                font-size: 34px;
                max-width: 523px;
                line-height: 40px;
            }

            p {
                font-size: 22px;
                max-width: 579px;
                line-height: 32px;
            }

            .get-started-wrapper {
                margin: 32.5px auto 0 auto;

                span {
                    font-size: 16px;
                }

                .arrow {
                    width: 17px;
                    height: 17px;
                    .retina('/img/purple-arrow@2x.png', 17px, 17px);
                }
            }
        }
    }
}