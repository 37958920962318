
.about{
	
	padding-left:20px;
	padding-right:20px;
	padding-bottom:50px;
	color:black;

	.black&{
		color:white;
		a{
			color:white;
		}
	}	
	
	.about-header {

	    .rule {
	        width:100%;
	        height:26px;

	        .ccnr;

	        .white&{
	            .retina('../img/module_header_rule_black@2x.png', 57px, 1px);
	        }

	        .black&{
	            .retina('../img/module_header_rule@2x.png', 57px, 1px);
	        }
	    }
	    
	    &.mobile {
	        padding-top:20px;

	        .number{
	            width:35px;
	            height:35px;
	            border-radius:50%;
	            .dinCondensedBold;
	            font-size:26px;
	            text-align:center;
	            line-height:41px;
	            margin:0 auto;
				background-image:url('/img/imposium_logo_square@2x.png');
				.black&{
					background-image:url('/img/imposium_logo_square_white@2x.png');					
				}
				background-size:100% 100%;
				background-repeat:no-repeat;
				background-position:center center;
	        }

	        .step{
	            display: none;
	        }

	        h2{
	            text-align:center;
	            color:white;
	            .dinCondensedBold;
	            text-transform:uppercase;
	            font-size:21px;
	            font-size: 25px;
	            line-height: 24px;
	            max-width:310px;
	            margin:10px auto 0px auto;

	            .black&{
	                color:white;
	            }

	            .white&{
	                color:@darkGreen;
	            }
	        }
	    }

	    &.desktop {
	        .flex-center-justify;
	        flex-direction: column;
	        padding: 50px 0 20px 0;

	        .number {
	            .dinCondensedBold;
	            width: 50px;
	            height: 50px;
	            border-radius: 50%;
	            font-size: 40px;
	            text-align: center;
	            line-height: 60px;
	            margin: 0 auto 10px auto;
				background-image:url('/img/imposium_logo_square@2x.png');
				.black&{
					background-image:url('/img/imposium_logo_square_white@2x.png');					
				}
				background-size:100% 100%;
				background-repeat:no-repeat;
				background-position:center center;
	        }

	        h2 {
	            .dinCondensedBold;
	            max-width: 470px;
	            font-size: 28px;
	            text-align: center;
	            text-transform: uppercase;
	            font-weight: 400;

	            // keep this here
	            @media @desktop {
	                max-width: 706px;
	                font-size: 42px;
	            }

	            .blue&{
	                color:white;
	            }

	            .white&{
	                color:@darkGreen;
	            }
	        }

	        .rule {
	            margin: 0;
	            margin-top: 20px;
	        }
	    }

	    @media @desktop {
	        padding: 80px 0 30px 0;

	        .number {
	            width: 75px;
	            height: 75px;
	            font-size: 42px;
	            line-height: 75px;
	            margin: 0 auto 20px auto;
	        }

	        .rule {
	            margin: 0;
	            margin-top: 30px;

	            .white&{
	                .retina('../img/module_header_rule_black@2x.png', 114px, 2px);
	            }

	            .black&{
	                .retina('../img/module_header_rule@2x.png', 114px, 2px);
	            }
	        }
	    }
	}

	p{
		font-family: CircularStd-Book;
	    font-weight: 400;
	    font-style: normal;
	    max-width: 630px;
	    font-size: 12px;
	    color:@textGrey;
	    letter-spacing: .025em;
	    margin-top: 10px;
	}

	a{
		color:@textGrey;
	}

	@media @laptop{

		p{
			max-width:650px;
			font-size:14px;
			margin-top:20px;
		}

		padding-bottom:100px;
	}

	@media @desktop{

		p{
			max-width: 975px;
			font-size:18px;
			margin-top:30px;
		}

		padding-bottom:150px;
	}
}