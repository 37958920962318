
@formMargin:4px;
@formElHeight:30px;
@formElPadding:10px;

.contact-info {
        
    .desc{
        color:white;
        .circularStdBook;
        font-size: 14px;
        max-width: 320px;
        font-size:11px;
        margin-top: -5px auto 16px auto;
        text-align:center;
        width:100%;

        .white&{
            color:@textGrey;
        }

        .blue&{
            color:white;
        }
    }

    .add-more{
        display: none;

        &.visible{
            display: block;
        }
    }

    .hide-more{
        display: none;

        &.visible{
            display: block;
        }
    }

    .more{
        display: none;

        &.visible{
            display: block;
        }
    }

    .formEl{
        width:100%;
        display: inline-block;
        height:@formElHeight;
        color:@gsGreen;
        background:white;
        .circularStdBold;
        margin-top:@formMargin;
        border:none;
        border-radius:0px;
        font-size:12px;
        line-height:@formElHeight;
        padding:0px @formElPadding 0px @formElPadding;
        margin:@formMargin 0px 0px 0px;
        box-sizing:border-box;
        -webkit-appearance: none;

        &::-webkit-input-placeholder {
          color: #87d195;
        }
        &::-moz-placeholder {
          color: #87d195;
        }
        &:-ms-input-placeholder {
          color: #87d195;
        }
        &:-moz-placeholder {
          color: #87d195;
        }
    }

    input{
        .formEl;

        &:-webkit-textfield-decoration-container {
            display: none;
        }

        &.office_name{
            text-indent:51px;
            margin:0px;
        }

        &.license_number{
            width:calc(50% - (@formMargin / 2));
        }

        &.mls_number{
            width:calc(50% - (@formMargin / 2));
            margin-left:@formMargin;
        }
    }

    .input-flex-wrapper {
        display: flex;

        .err-state-wrapper {
            &.name{
                width:calc(50% - (@formMargin / 2));
            }

            &.title{
                width:calc(50% - (@formMargin / 2));
                margin-left:@formMargin;
            }
        }
    }

    .office {
        position: relative;
        width:100%;
        height:@formElHeight;
        margin:@formMargin 0px 0px 0px;

        &.office-err {
            height: 58px;
        }

        .prefix {
            font-size:12px;
            .circularStdBold;
            position: absolute;
            top:0px;
            left:0px;
            width:60px;
            color:@gsGreen;
            line-height:@formElHeight + 1px;
            padding-left:10px;
            box-sizing: border-box;
        }
    }

    select{
        .formEl;
        color:@bgBlue;
        background-image: url('../../img/select_arrow@2x.png');
        background-position: calc(100% - 5px) calc(1em + -6px);
        background-size: 17px 17px;
        background-repeat: no-repeat;
        cursor:pointer;

        &.chosen{
            color:@gsGreen;
        }
    }

    input, select {
        font-variant-ligatures: none;

        &.show-error {
            &:invalid {
                border: 1px solid #dc1c2e;
                background-color: #fbe7e9;
            }
        }
    }

    .input-error {
        .circularStdBook;
        font-size: 11px;
        color: #dc1c2e;
        letter-spacing: 0.02em;
    }

    .toggle-more{

        width:100%;
        height:18px;
        padding-top:5px;
        color:white;
        .circularStdBold;
        font-size:12px;

        .btn-toggle-more{
            vertical-align: top;
            display: inline-block;
            width:17px;
            height:17px;
            background:@darkGreen;
            border:none;
            margin-right:5px;
            text-align:center;

            .icon-plus{
                margin-top:4px;
                transform-origin: center center;
                transition:transform 300ms @easeOutQuint;
            }

            .icon-minus{
                margin-bottom: 3px;
            }

            &:hover{
                .icon-plus{
                    transform:rotate(90deg);
                }
            }
        }
    }

    @media @laptop {

        .desc{
            .circularStdBook;
            max-width: 630px;
            font-size: 14px;
            letter-spacing: 0.025em;
            margin: 0 auto 40px auto;

            &.tip {
                color: white;
                font-style: italic;
                margin-bottom: 40px;
            }
        }

        .flex-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .formEl {
                margin-top: 10px;
            }

            .col {
                width: 470px;

                &:first-child {
                    margin-right: 20px;
                }

                select {
                    margin-top: 3px;
                    background-position: calc(100% - 8px) calc(1em + -6px);
                    background-size: 23px 23px;
                }

                input, select {
                    width: 470px;
                    height: 40px;
                    font-size: 14px;
                    letter-spacing: 0.02em;

                    &.license_number, &.mls_number {
                        width: 233px;
                    }

                    .mls-number {
                        margin-left: 10px;
                    }
                }

                .input-flex-wrapper {
                    input {
                        width: 100%;
                    }

                    .err-state-wrapper {
                        &.name{
                            width:calc(50% - (@formMargin / 2));
                        }

                        &.title{
                            width:calc(50% - (@formMargin / 2));
                        }
                    }
                }

                .office{
                    height: 40px;
                    width: 470px;

                    &.office-err {
                        height: 64px;
                    }

                    input {
                        text-indent: 65px;
                    }

                    .prefix {
                        font-size: 14px;
                        line-height: 41px;
                    }
                }

                .toggle-more {
                    width: 470px;

                    .icon-minus {
                        margin-top: 0;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

    @media @desktop {

        .desc{
            max-width: 1000px;
            font-size: 22px;

            &.tip {
                margin-bottom: 60px;
            }
        }

        .flex-wrapper {
            .col {
                width: 706px;

                &:first-child {
                    margin-right: 28px;
                }

                select {
                    margin-top: 4px !important;
                    background-position: calc(100% - 13px) calc(1em + -8px);
                    background-size: 35px 35px;
                }

                input, select {
                    .circularStdBold;
                    margin-top: 7px;
                    width: 706px;
                    height: 60px;
                    font-size: 22px;

                    &.name, &.title {
                        width: 706px;
                    }

                    &.license_number, &.mls_number {
                        width: 349.5px;
                    }

                    &.mls_number {
                        margin-left: 7px;
                    }
                }

                .input-flex-wrapper {
                    input {
                        width: 100%;
                    }

                    .err-state-wrapper {
                        &.name{
                            width:calc(50% - (7px / 2));
                        }

                        &.title{
                            width:calc(50% - (7px / 2));
                            margin-left: 7px;
                        }
                    }
                }

                .office{
                    height: 60px;
                    width: 706px;
                    margin: 7px 0 0 0;

                    &.office-err {
                        height: 84px;
                    }

                    input {
                        margin-top: 0;
                        text-indent: 100px;
                    }

                    .prefix {
                        font-size: 22px;
                        line-height: 60px;
                    }
                }

                .input-error {
                    font-size: 14px;
                }

                input, select {
                    &.show-error {
                        &:invalid {
                            border: 2px solid #dc1c2e;
                        }
                    }
                }

                .toggle-more {
                    width: 706px;
                    font-size: 18px;

                    .btn-toggle-more {
                        width: 21px;
                        height: 21px;

                        .icon-plus {
                            margin-top: 0;
                            margin-bottom: 5px;
                        }

                        .icon-minus {
                            margin-top: 0;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}
